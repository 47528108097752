
export const Queryparams = {
    url: "https://ddnapi.msd4p.com/api/v1",
    powerkey: "poTp0tv0Lt3s5v4h",
    areanamepdf: "DAVAO DEL NORTE",
    areapref: "ddn"
}

export const clrs = {
    bglogin: "#001026",
    bgloginmiddle: "#001026",
    lable: "#004AAD",
    white: "#AAC2E3",
    brdr: "#004AAD",
    txtinptbrdr: "#004AAD",
    btnhvr: "#AAC2E3"
}